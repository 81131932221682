import { createSlice } from "@reduxjs/toolkit";
import { detectPlate } from "../API";

export const plateSlice = createSlice({
  name: "plate",
  initialState: {
    loading: false,
    data: null,
  },
  reducers: {
    loading: (state) => {
      state.loading = true;
    },
    error: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    reset: (state) => {
      state.data = null;
      state.error = null;
      state.loading = false;
    },
    detect: (state, { payload }) => {
      state.data = payload.data;
      state.loading = false;
      sessionStorage.setItem("plate", JSON.stringify(state.data));
    },
    update: (state, { payload }) => {
      state.data = { ...state.data, text: payload };
      sessionStorage.setItem("plate", JSON.stringify(state.data));
    },
    check: (state, { payload }) => {
      state.data = payload;
    },
  },
});

export const { loading, error, detect, reset, update, check } = plateSlice.actions;

export const doDetection = ({ image, login }) => (dispatch) => {
  dispatch(loading());
  detectPlate({ image, login })
    .then((data) => {
      let payload = "";
      if (data.data.text.length > 10) {
        payload = "Certifique-se de que apenas a placa esteja visível.";
      } else if (data.data.text.length === 0) {
        payload = "Nenhum texto visível na imagem.";
      }

      if (payload) {
        dispatch(error(payload));
      } else {
        dispatch(detect(data));
      }
    })
    .catch((e) => {
      let payload = "Algo deu errado, tente novamente.";
      dispatch(error(payload));
    });
};

/**
 * Resets the state to the default
 * @function doReset
 */
export const doReset = () => (dispatch) => {
  console.log("Reseting");
  dispatch(reset);
};

export const doUpdate = ({ payload }) => (dispatch) => {
  dispatch(update(payload));
};

/**
 * Loads state from session storage
 * @function doCheckAxis
 */
export const doCheckPlate = () => (dispatch) => {
  const payload = JSON.parse(sessionStorage.getItem("plate"));
  dispatch(check(payload));
};

export default plateSlice.reducer;
