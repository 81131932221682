import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../../utils/Spinner";
import Navbar from "../Dashboard/Navbar";
import Topbar from "../Dashboard/Topbar";
import PanelSingleImage from "../../utils/PanelSingleImage";
import { doBarcodeRead, reset, doUpdate } from "./barcodeSlice";

const Barcode = ({ user, loading, nfe, doBarcodeRead, doUpdate, reset }) => {
  const drawFunction = (data, ctx) => {
    if (data.hasOwnProperty("data") && data["data"]) {
      ctx.globalAlpha = 0.8;
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, 800, 40);

      ctx.globalAlpha = 1;
      ctx.font = "bold 25px Lato";
      ctx.fillStyle = "black";
      ctx.textBaseline = "bottom";
      ctx.fillText(`NFe: ${data.data.codeResult.code}`, 5, 35);
    }
  };

  const buildInfoPanel = (data) => {
    if (data === null) {
      data = { codeResult: { code: "" } };
    }
    return (
      <div className='form-row col-12'>
        <h3>Resultados</h3>
        <div className='col-12 text-left'>
          <p>Código de Barras:</p>
        </div>
        <div className='form-group col-9'>
          <input
            type='text'
            id='plate-custom'
            className='form-control'
            value={data.codeResult.code}
            onChange={(e) => {
              doUpdate({ payload: e.target.value });
            }}
          />
        </div>
        <div className='form-group col-3'>
          <button
            className='btn btn-primary btn-block'
            onClick={(e) => {
              e.preventDefault();
              doUpdate({ payload: "" });
            }}
          >
            <i className='fa fa-eraser'></i>
          </button>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <div className='wrapper'>
          <Navbar activeClass='barcode' />

          <div id='content'>
            <Topbar username={user} />

            <h4 className='title'>Nota Fiscal</h4>
            <Link to='/dashboard' className='btn btn-link'>
              <i className='fa fa-arrow-left'></i>&nbsp;&nbsp;Voltar
            </Link>

            <PanelSingleImage
              message='Fotografe o código de barras da NF na horizontal'
              imageSrc='https://img.icons8.com/color/480/000000/barcode.png'
              target={doBarcodeRead}
              drawFunction={drawFunction}
              resetGlobalState={reset}
              loading={nfe.loading}
              buildInfoPanel={buildInfoPanel}
              data={nfe}
              nextStepURL='/report'
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

Barcode.propTypes = {
  user: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  nfe: PropTypes.object.isRequired,
  doBarcodeRead: PropTypes.func.isRequired,
  doUpdate: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.login.user,
  loading: state.login.loading,
  nfe: state.nfe,
});

export default connect(mapStateToProps, { doBarcodeRead, doUpdate, reset })(Barcode);
