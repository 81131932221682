import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../Login/loginSlice";

import logo from "./pixforce-logo.png";

const Navbar = ({ logoutUser, activeClass }) => {
  return (
    <nav id='sidebar'>
      <div className='sidebar-header'>
        <img src={logo} height={35} alt='PixForce'></img>
      </div>

      <ul className='list-unstyled components'>
        <li className={activeClass === "dashboard" ? "active" : ""}>
          <Link to='/dashboard'>
            <i className='fa fa-tachometer'></i>
            Dashboard
          </Link>
        </li>
        <li className={activeClass === "axis" ? "active" : ""}>
          <Link to='/axis'>
            <i className='fa fa-truck'></i>
            Caminhões
          </Link>
        </li>
        <li className={activeClass === "face" ? "active" : ""}>
          <Link to='/faces'>
            <i className='fa fa-user'></i>
            Faces
          </Link>
        </li>
        <li className={activeClass === "document" ? "active" : ""}>
          <Link to='/documents'>
            <i className='fa fa-id-card-o'></i>
            Documentos
          </Link>
        </li>
        <li className={activeClass === "plate" ? "active" : ""}>
          <Link to='/plates'>
            <i className='fa fa-car'></i>
            Placas
          </Link>
        </li>
        <li className={activeClass === "barcode" ? "active" : ""}>
          <Link to='/barcode'>
            <i className='fa fa-barcode'></i>
            Nota Fiscal
          </Link>
        </li>
        <li className={activeClass === "report" ? "active" : ""}>
          <Link to='/report'>
            <i className='fa fa-file-text-o'></i>
            Relatório
          </Link>
        </li>
        <li>
          <Link to='/' onClick={() => logoutUser()}>
            <i className='fa fa-sign-out'></i>
            Sair
          </Link>
        </li>
      </ul>

      <ul className='list-unstyled CTAs'>
        <li>
          <a href='http://www.pixforce.com.br' className='download'>
            Mais sobre a PixForce
          </a>
        </li>
        <li>
          <a href='mailto:pixforce@pixforce.com.br' className='article'>
            Entrar em contato
          </a>
        </li>
      </ul>
    </nav>
  );
};

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  activeClass: PropTypes.string,
};

export default connect(null, { logoutUser })(Navbar);
