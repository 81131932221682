export const config = {
  API_URL: "https://demoapi.gate.pixforcemaps.com",
  API_ENDPOINTS: {
    LOGIN: "/users/login",
    LOGOUT: "/users/logout",
    STATUS: "/status",
    FACES: "/faces/compare",
    AXIS: "/axis/estimate",
    PLATES: "/plates/ocr",
  },
  PIXID_URL: "https://tickets.pixid.pixforcemaps.com/api/v1",
  PIXID_ENDPOINTS: {
    REGISTER: "/Ticket/PostTicketClient",
    GET_TICKET: "/Ticket/GetByTicket/",
    GET_FILE_PROCESSED_BY_TICKET: "/Ticket/GetArquivoProcessadoByTicket/",
    GET_OCR_STRUCTURED_BY_TICKET: "/Ticket/GetDadosOCRStructuredByTicket/",
    GET_DOCUMENT_STATUS_BY_TICKET: "/Ticket/GetProcessamentoDocumentoByTicket/",
  },
};
