import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../../utils/Spinner";
import Navbar from "../Dashboard/Navbar";
import Topbar from "../Dashboard/Topbar";
import PanelSingleImage from "../../utils/PanelSingleImage";
import { doDocumentScan, reset } from "./documentSlice";

const Document = ({ user, loading, id, doDocumentScan, reset }) => {
  const drawFunction = (data, ctx) => {};

  const buildInfoPanel = (data) => {
    if (data === null) {
      data = { codeResult: { code: "" } };
    }
    return (
      <div className='form-row col-12'>
        <h3>Resultados</h3>
        <div className='col-12 text-left'>
          <p>OCR: Em manutenção...</p>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <div className='wrapper'>
          <Navbar activeClass='document' />

          <div id='content'>
            <Topbar username={user} />

            <h4 className='title'>OCR de Documentos</h4>
            <Link to='/dashboard' className='btn btn-link'>
              <i className='fa fa-arrow-left'></i>&nbsp;&nbsp;Voltar
            </Link>

            <PanelSingleImage
              message='Fotografe a CNH'
              imageSrc='https://img.icons8.com/color/480/000000/driver-license-card.png'
              target={doDocumentScan}
              drawFunction={drawFunction}
              resetGlobalState={reset}
              loading={id.loading}
              buildInfoPanel={buildInfoPanel}
              data={id}
              nextStepURL='/plates'
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

Document.propTypes = {
  user: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  id: PropTypes.object.isRequired,
  doDocumentScan: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.login.user,
  loading: state.login.loading,
  id: state.id,
});

export default connect(mapStateToProps, { doDocumentScan, reset })(Document);
