import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

import { checkUser } from "./components/modules/Login/loginSlice";

import Login from "./components/modules/Login/Login";
import PrivateRoute from "./components/routes/PrivateRoute/PrivateRoute";

import "./App.css";
import Dashboard from "./components/modules/Dashboard/Dashboard";
import Truck from "./components/modules/Truck/Truck";
import Face from "./components/modules/Face/Face";
import Plate from "./components/modules/Plate/Plate";
import Document from "./components/modules/Document/Document";
import Barcode from "./components/modules/Barcode/Barcode";
import Report from "./components/modules/Report/Report";

function App({ checkUser, isAuthenticated }) {
  useEffect(() => {
    checkUser();
  }, [checkUser]);

  return (
    <Router>
      <Switch>
        <Route exact path='/' component={Login} />
        <PrivateRoute exact path='/dashboard' component={Dashboard} />
        <PrivateRoute exact path='/axis' component={Truck} />
        <PrivateRoute exact path='/faces' component={Face} />
        <PrivateRoute exact path='/plates' component={Plate} />
        <PrivateRoute exact path='/documents' component={Document} />
        <PrivateRoute exact path='/barcode' component={Barcode} />
        <PrivateRoute exact path='/report' component={Report} />
        <Route component={NotFound}></Route>
      </Switch>
    </Router>
  );
}

function NotFound() {
  return <div>404 Page Not Found</div>;
}

App.propTypes = {
  checkUser: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.login.isAuthenticated,
});

export default connect(mapStateToProps, { checkUser })(App);
