import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../../utils/Spinner";
import Navbar from "../Dashboard/Navbar";
import Topbar from "../Dashboard/Topbar";
import PanelSingleImage from "../../utils/PanelSingleImage";
import { doDetection, doUpdate, reset } from "./plateSlice";

const Plate = ({ user, loading, doUpdate, plate, reset, doDetection }) => {
  const drawFunction = (data, ctx) => {
    if (data.hasOwnProperty("data") && data["data"]) {
      ctx.globalAlpha = 0.8;
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, 210, 40);

      ctx.globalAlpha = 1;
      ctx.font = "bold 25px Lato";
      ctx.fillStyle = "black";
      ctx.textBaseline = "bottom";
      ctx.fillText(`Placa: ${data.data.text}`, 5, 35);
    }
  };

  const buildInfoPanel = (data) => {
    if (data === null) {
      data = { text: "" };
    }
    return (
      <div className='form-row col-12'>
        <h3>Resultados</h3>
        <div className='col-12 text-left'>
          <p>Placa detectada:</p>
        </div>
        <div className='form-group col-9'>
          <input
            type='text'
            id='plate-custom'
            className='form-control'
            value={data.text}
            onChange={(e) => {
              doUpdate({ payload: e.target.value });
            }}
          />
        </div>
        <div className='form-group col-3'>
          <button
            className='btn btn-primary btn-block'
            onClick={(e) => {
              e.preventDefault();
              doUpdate({ payload: "" });
            }}
          >
            <i className='fa fa-eraser'></i>
          </button>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <div className='wrapper'>
          <Navbar activeClass='plate' />

          <div id='content'>
            <Topbar username={user} />

            <h4 className='title'>OCR de Placas</h4>
            <Link to='/dashboard' className='btn btn-link'>
              <i className='fa fa-arrow-left'></i>&nbsp;&nbsp;Voltar
            </Link>

            <PanelSingleImage
              message='Fotografe a placa do veículo'
              imageSrc='https://image.flaticon.com/icons/svg/290/290122.svg'
              target={doDetection}
              drawFunction={drawFunction}
              resetGlobalState={reset}
              loading={plate.loading}
              data={plate}
              nextStepURL='/barcode'
              buildInfoPanel={buildInfoPanel}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

Plate.propTypes = {
  user: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  plate: PropTypes.object.isRequired,
  doDetection: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.login.user,
  loading: state.login.loading,
  plate: state.plate,
});

export default connect(mapStateToProps, { doDetection, doUpdate, reset })(Plate);
