import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../components/modules/Login/loginSlice";
import truckReducer from "../components/modules/Truck/truckSlice";
import faceReducer from "../components/modules/Face/faceSlice";
import plateReducer from "../components/modules/Plate/plateSlice";
import barcodeSlice from "../components/modules/Barcode/barcodeSlice";
import documentSlice from "../components/modules/Document/documentSlice";

export default configureStore({
  reducer: {
    login: loginReducer,
    axis: truckReducer,
    face: faceReducer,
    plate: plateReducer,
    nfe: barcodeSlice,
    id: documentSlice,
  },
});
