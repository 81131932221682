import React from "react";
import PropTypes from "prop-types";
import animation from "./loading.gif";

const Spinner = ({ message }) => {
  return (
    <div>
      <img src={animation} width={320} alt='Processando...' />
      {message && <h5>{message}</h5>}
    </div>
  );
};

Spinner.propTypes = {
  message: PropTypes.string,
};

export default Spinner;
