import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LoadImage from "blueimp-load-image";
import "./PanelSingleImage.css";
import { connect } from "react-redux";
import Spinner from "./Spinner";
import Message from "./Message";
import { Link } from "react-router-dom";

const PanelSingleImage = ({
  login,
  data,
  nextStepURL,
  imageSrc,
  message,
  target,
  resetGlobalState,
  loading,
  drawFunction,
  buildInfoPanel,
}) => {
  const [formData, setFormData] = useState({
    imageContent: null,
    data,
  });
  const [enableForward, setEnableForward] = useState(false);

  /**
   * Resets the current form state and canvas panel
   */
  const formReset = (e) => {
    e.preventDefault();
    setEnableForward(false);
    resetGlobalState();
    setFormData({ imageContent: null });
    document.getElementById("form-image").value = "";
    const canvas = document.getElementById("canvas-panel");
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const submit = (e) => {
    e.preventDefault();
    var canvas = document.getElementById("canvas-panel");
    canvas.toBlob((imageData) => {
      target({ image: imageData, login: login });
      setEnableForward(true);
    });
  };

  /**
   * Update the canvas with the image
   */
  const updateCanvas = () => {
    if (formData.imageContent && document.getElementById("canvas-panel")) {
      var canvas = document.getElementById("canvas-panel");
      var queryImage = new Image();
      const ctx = canvas.getContext("2d");

      LoadImage(
        URL.createObjectURL(formData.imageContent),
        function (image) {
          image.toBlob(function (e) {
            var blob = window.URL.createObjectURL(e);
            queryImage.src = blob;
          });
        },
        {
          orientation: true,
          canvas: true,
          maxWidth: 800,
          maxHeight: 600,
          noRevoke: true,
        }
      );

      queryImage.onload = function () {
        canvas.width = queryImage.width;
        canvas.height = queryImage.height;
        ctx.drawImage(queryImage, 0, 0);
        drawFunction(data, ctx);
      };
    }
  };

  useEffect(updateCanvas, [formData.imageContent, data]);

  return (
    <form className='center'>
      <br />
      {data.error && <Message label={data.error} type='danger'></Message>}

      {!loading ? (
        <div className='form-row col-12'>
          <div className='panel-container' hidden={formData.imageContent !== null}>
            <div className='panel-box'>
              <img className='icon-image' src={imageSrc} height={200} alt='Ícone Upload' />
              <div className='message-box'>
                <h5 className='button-header'>{message}</h5>
                <input
                  accept='image/*'
                  capture='enviroment'
                  type='file'
                  id='form-image'
                  className='btn btn-light'
                  onChange={(e) => {
                    setFormData({ imageContent: e.target.files[0] });
                  }}
                />
                <label htmlFor='form-image'>
                  <span className='btn btn-outline-info'>Selecionar Imagem</span>
                </label>
              </div>
            </div>
          </div>

          <div
            className='view-container'
            id='view-container'
            hidden={formData.imageContent === null}
          >
            <div className='center'>
              <canvas id='canvas-panel'></canvas>
            </div>
          </div>

          {/* Form to visualize or Edit information */}
          {enableForward && buildInfoPanel(data.data || null)}

          <div className='form-row col-12' hidden={formData.imageContent === null}>
            <div className='form-group col-6'>
              <button className='btn btn-primary btn-block' onClick={(e) => formReset(e)}>
                <i className='fa fa-refresh'></i>&nbsp;&nbsp;Reiniciar
              </button>
            </div>
            {enableForward && !loading ? (
              <div className='form-group col-6'>
                <Link className='btn btn-primary btn-block' to={nextStepURL}>
                  <i className='fa fa-arrow-right'></i>&nbsp;&nbsp;Próximo
                </Link>
              </div>
            ) : (
              <div className='form-group col-6'>
                <button className='btn btn-primary btn-block' onClick={(e) => submit(e)}>
                  <i className='fa fa-search'></i>&nbsp;&nbsp;Analisar
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <Spinner
          message={
            data.data &&
            data.data.hasOwnProperty("statusProcessamento") &&
            data.data.statusProcessamento
          }
        />
      )}
    </form>
  );
};

PanelSingleImage.propTypes = {
  // Custom attributes
  message: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  drawFunction: PropTypes.func.isRequired,
  buildInfoPanel: PropTypes.func,
  data: PropTypes.object,
  resetGlobalState: PropTypes.func.isRequired,
  nextStepURL: PropTypes.string.isRequired,
  // State objects
  login: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  login: state.login,
});

export default connect(mapStateToProps, {})(PanelSingleImage);
