import React from "react";
import PropTypes from "prop-types";

const Message = ({ label, type }) => {
  return (
    <div className={`alert alert-${type} alert-dismissible fade show`}>
      {label}
    </div>
  );
};

Message.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Message;
