import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../../utils/Spinner";
import Navbar from "../Dashboard/Navbar";
import Topbar from "../Dashboard/Topbar";

import { doCheckAxis } from "../Truck/truckSlice";
import { doCheckFaces } from "../Face/faceSlice";
import { doCheckPlate } from "../Plate/plateSlice";
import { doCheckBarcode } from "../Barcode/barcodeSlice";

const Report = ({
  user,
  loading,
  axis,
  doCheckAxis,
  faces,
  doCheckFaces,
  plate,
  doCheckPlate,
  nfe,
  doCheckBarcode,
}) => {
  useEffect(doCheckAxis, []);
  useEffect(doCheckFaces, []);
  useEffect(doCheckPlate, []);
  useEffect(doCheckBarcode, []);

  const getPBTC = () => {
    var temp = 0;
    axis.data.axis.forEach(function (ax) {
      temp += ax.max_weight;
    });
    return temp;
  };

  const getAxis = () => {
    let list = [];

    var i = 0;
    axis.data.axis.forEach(function (ax) {
      var roll_type_pt = ax.roll_type;
      switch (ax.roll_type) {
        case "single":
          roll_type_pt = "simples";
          break;
        case "double":
          roll_type_pt = "duplo";
          break;
        case "tandem":
          roll_type_pt = "tandem";
          break;
        case "extra":
          roll_type_pt = "extra";
          break;
        default:
          roll_type_pt = ax.roll_type;
          break;
      }
      list.push(
        <Fragment key={i++}>
          <dt>Grupo {ax.group_id + 1}</dt>
          <dd>
            {roll_type_pt} / {ax.size} {ax.size === 1 ? "eixo" : "eixos"} / {ax.max_weight}ton
          </dd>
        </Fragment>
      );
    });

    return list;
  };

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <div className='wrapper'>
          <Navbar activeClass='report' />

          <div id='content'>
            <Topbar username={user} />

            <h4 className='title'>Relatório</h4>
            <Link to='/dashboard' className='btn btn-link'>
              <i className='fa fa-arrow-left'></i>&nbsp;&nbsp;Voltar
            </Link>
            <hr />
            <div className='form-row col-12'>
              {axis.data && (
                <Fragment>
                  <section>
                    <h4>Detecção de Eixos</h4>
                    <dl>
                      <dt>Grupo de Eixos</dt>
                      <dd>{axis.data.axis.length} grupos</dd>
                      <dt>PBTC</dt>
                      <dd>{getPBTC()}ton</dd>
                      {getAxis()}
                    </dl>
                  </section>
                  <hr />
                </Fragment>
              )}
              {faces.data && (
                <Fragment>
                  <section>
                    <h4>Comparação de Faces</h4>
                    <dl>
                      <dt>Resultado da Comparação</dt>
                      <dd>Foto{faces.data.match ? " " : " não "}confere com o documento</dd>
                    </dl>
                  </section>
                  <hr />
                </Fragment>
              )}
              {plate.data && (
                <Fragment>
                  <section>
                    <h4>Reconhecimento de Placas</h4>
                    <dl>
                      <dt>OCR Placa</dt>
                      <dd>{plate.data.text}</dd>
                    </dl>
                  </section>
                  <hr />
                </Fragment>
              )}
              {nfe.data && (
                <Fragment>
                  <section>
                    <h4>Leitura de NFe</h4>
                    <dl>
                      <dt>NFe</dt>
                      <dd>{nfe.data.codeResult.code}</dd>
                    </dl>
                  </section>
                  <hr />
                </Fragment>
              )}
            </div>

            <div>
              <Link
                className='btn btn-primary'
                onClick={(e) => {
                  sessionStorage.removeItem("nfe");
                  sessionStorage.removeItem("face");
                  sessionStorage.removeItem("axis");
                  sessionStorage.removeItem("plate");
                }}
                to='/dashboard'
              >
                <i className='fa fa-eraser'></i>&nbsp;&nbsp;Limpar Dados
              </Link>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.login.user,
  loading: state.login.loading,
  axis: state.axis,
  faces: state.face,
  plate: state.plate,
  nfe: state.nfe,
});

export default connect(mapStateToProps, {
  doCheckAxis,
  doCheckFaces,
  doCheckPlate,
  doCheckBarcode,
})(Report);
