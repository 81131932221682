import { createSlice } from "@reduxjs/toolkit";
import { compareFaces } from "../API";

export const faceSlice = createSlice({
  name: "face",
  initialState: {
    loading: false,
    data: null,
  },
  reducers: {
    loading: (state) => {
      state.loading = true;
    },
    error: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    reset: (state) => {
      state.data = null;
      state.error = null;
      state.loading = false;
    },
    compare: (state, { payload }) => {
      state.data = payload.data;
      state.loading = false;

      sessionStorage.setItem("face", JSON.stringify(payload.data));
    },
    check: (state, { payload }) => {
      state.data = payload;
    },
  },
});

export const { loading, error, compare, reset, check } = faceSlice.actions;

export const doCompare = ({ image1, image2, login }) => (dispatch) => {
  dispatch(loading());
  compareFaces({ image1, image2, login })
    .then((data) => {
      let payload = "";
      if (data.data.faces.image1 === null && data.data.faces.image2 === null) {
        payload = "Não foi possível detectar uma face em ambas as imagens.";
      } else if (data.data.faces.image1 !== null && data.data.faces.image2 === null) {
        payload = "Não foi possível detectar uma face no documento.";
      } else if (data.data.faces.image1 === null && data.data.faces.image2 !== null) {
        payload = "Não foi possível detectar uma face na foto da pessoa.";
      }

      if (payload) {
        dispatch(error(payload));
      } else {
        dispatch(compare(data));
      }
    })
    .catch((e) => {
      let payload = "Algo deu errado, tente novamente.";
      dispatch(error(payload));
    });
};

/**
 * Resets the state to the default
 * @function doReset
 */
export const doReset = () => (dispatch) => {
  dispatch(reset);
};

/**
 * Loads state from session storage
 * @function doCheckFaces
 */
export const doCheckFaces = () => (dispatch) => {
  const payload = JSON.parse(sessionStorage.getItem("face"));
  dispatch(check(payload));
};

export default faceSlice.reducer;
