import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Spinner from "../../utils/Spinner";
import Navbar from "../Dashboard/Navbar";
import Topbar from "../Dashboard/Topbar";
import PanelSingleImage from "../../utils/PanelSingleImage";
import { doCount, reset } from "./truckSlice";

const Truck = ({ loading, user, doCount, reset, axisLoading, axis }) => {
  /**
   * This functions implements the method to draw the detected bouding boxes
   * @function drawFunction
   * @param {Object} data - contains the object to be drawn
   * @param {HTMLCanvasElement} - contains the canvas where the bouding boxes will be drawn
   *  */
  const drawFunction = (data, ctx) => {
    if (
      data.hasOwnProperty("cockpit") ||
      data.hasOwnProperty("axis") ||
      data.hasOwnProperty("fuelTanks")
    ) {
      var pbtc = 0;
      if (data.hasOwnProperty("cockpit") && data["cockpit"]) {
        data["cockpit"].forEach(function (item) {
          var top = item.bbox.top;
          var left = item.bbox.left;
          var right = item.bbox.right;
          var bot = item.bbox.bot;
          ctx.strokeStyle = "black";
          ctx.globalAlpha = 1.0;
          ctx.lineWidth = 1;
          ctx.strokeRect(left, top, right - left, bot - top);
          ctx.fillStyle = "blue";
          ctx.globalAlpha = 0.2;
          ctx.fillRect(left, top, right - left, bot - top);

          // Escrevendo os rótulos da classe cabine

          ctx.globalAlpha = 1;

          ctx.fillRect(left, top, right - left, 23);
          ctx.strokeRect(left, top, right - left, 23);

          ctx.font = "15px Lato";
          ctx.fillStyle = "white";
          ctx.textBaseline = "bottom";

          ctx.fillText("Cabine", left + 5, top + 20);
        });
      }
      if (data.hasOwnProperty("fuelTanks") && data["fuelTanks"]) {
        data["fuelTanks"].forEach(function (item) {
          var top = item.bbox.top;
          var left = item.bbox.left;
          var right = item.bbox.right;
          var bot = item.bbox.bot;
          ctx.strokeStyle = "red";
          ctx.globalAlpha = 1.0;
          ctx.lineWidth = 3;

          ctx.strokeRect(left, top, right - left, bot - top);
          ctx.fillStyle = "red";
          ctx.globalAlpha = 0.2;
          ctx.fillRect(left, top, right - left, bot - top);
        });
      }
      if (data.hasOwnProperty("axis") && data["axis"]) {
        data["axis"].forEach(function (groupAxis) {
          pbtc += groupAxis.max_weight;

          const border = 15;

          var top = groupAxis.bbox.top - border;
          var left = groupAxis.bbox.left - border;
          var right = groupAxis.bbox.right + border;
          var bot = groupAxis.bbox.bot + border;

          ctx.strokeStyle = "black";
          ctx.globalAlpha = 1.0;
          ctx.lineWidth = 1;

          ctx.strokeRect(left, top, right - left, bot - top);
          ctx.fillStyle = "green";
          ctx.globalAlpha = 0.5;
          ctx.fillRect(left, top, right - left, bot - top);

          // Escrevendo os rótulos do Eixo

          ctx.globalAlpha = 1;
          ctx.fillRect(left, top - 40, right - left, 40);
          ctx.strokeRect(left, top - 40, right - left, 40);
          ctx.font = "13px Lato";
          ctx.fillStyle = "white";
          ctx.textBaseline = "bottom";
          ctx.fillText("Eixo " + (groupAxis.group_id + 1), left + 5, top - 20);
          ctx.font = "13px Lato";
          ctx.fillText(groupAxis.max_weight + "ton", left + 5, top - 3);

          groupAxis["wheels"].forEach(function (wheel) {
            var top = wheel.bbox.top;
            var left = wheel.bbox.left;
            var right = wheel.bbox.right;
            var bot = wheel.bbox.bot;
            ctx.strokeStyle = "white";
            ctx.globalAlpha = 1.0;
            ctx.lineWidth = 3;
            ctx.strokeRect(left, top, right - left, bot - top);
            ctx.fillStyle = "white";
            ctx.globalAlpha = 0.5;
            ctx.fillRect(left, top, right - left, bot - top);
          });
        });
        ctx.globalAlpha = 0.8;
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, 175, 40);

        ctx.globalAlpha = 1;
        ctx.font = "bold 25px Lato";
        ctx.fillStyle = "black";
        ctx.textBaseline = "bottom";
        ctx.fillText("PBTC: " + pbtc + "ton", 5, 35);
      }
    }
  };

  const buildInfoPanel = (data) => {};

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <div className='wrapper'>
          <Navbar activeClass='axis' />

          <div id='content'>
            <Topbar username={user} />

            <h4 className='title'>Caminhão</h4>
            <Link to='/dashboard' className='btn btn-link'>
              <i className='fa fa-arrow-left'></i>&nbsp;&nbsp;Voltar
            </Link>

            <PanelSingleImage
              message='Fotografe a lateral do caminhão'
              imageSrc='https://img.icons8.com/color/480/container-truck.png'
              target={doCount}
              drawFunction={drawFunction}
              resetGlobalState={reset}
              buildInfoPanel={buildInfoPanel}
              loading={axisLoading}
              data={axis}
              nextStepURL='/faces'
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

Truck.propTypes = {
  user: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  doCount: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  axisLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.login.user,
  loading: state.login.loading,
  axisLoading: state.axis.loading,
  axis: state.axis,
});

export default connect(mapStateToProps, { doCount, reset })(Truck);
