import { config } from "../../../app/config";
import axios from "axios";

export const doLogin = async ({ username, password }) => {
  const URL = config.API_URL + config.API_ENDPOINTS.LOGIN;

  const res = await axios.post(URL, {
    user: username,
    pw: password,
  });

  const token = await res.data.data.session_token;

  return { token };
};

export const countAxis = async ({ image, login }) => {
  const URL = config.API_URL + config.API_ENDPOINTS.AXIS;

  const formData = new FormData();

  formData.append("image", image);
  var bearer = "Bearer " + login.token;

  const res = await axios.post(URL, formData, {
    headers: {
      Authorization: bearer,
    },
  });

  const data = await res.data.data;

  return { data };
};

export const compareFaces = async ({ image1, image2, login }) => {
  const URL = config.API_URL + config.API_ENDPOINTS.FACES;

  const formData = new FormData();

  formData.append("image1", image1);
  formData.append("image2", image2);

  var bearer = "Bearer " + login.token;

  const res = await axios.post(URL, formData, {
    headers: {
      Authorization: bearer,
    },
  });

  const data = await res.data.data;

  return { data };
};

export const detectPlate = async ({ image, login }) => {
  const URL = config.API_URL + config.API_ENDPOINTS.PLATES;

  const formData = new FormData();

  formData.append("image", image);
  var bearer = "Bearer " + login.token;

  const res = await axios.post(URL, formData, {
    headers: {
      Authorization: bearer,
    },
  });

  const data = await res.data.data;

  return { data };
};

export const readBarcode = async ({ image }) => {};

export const registerTicket = async ({ image }) => {
  const URL = config.PIXID_URL + config.PIXID_ENDPOINTS.REGISTER;
  const body = {
    idExterno: 666,
    idEmpresa: 666,
    idUsuarioCriacao: 666,
    idTipoProcessamento: 1,
    urlRetornoTicket: "",
    arquivos: [image.split(",")[1]],
  };

  const res = await axios.post(URL, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  const data = await res.data;

  return { data };
};

export const getTicketStatus = async ({ ticket }) => {
  const URL = config.PIXID_URL + config.PIXID_ENDPOINTS.GET_TICKET;

  const res = await axios.get(URL + ticket, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  const data = await res.data;

  return { data };
};

export const getOCRResults = async ({ ticket }) => {
  const URL = config.PIXID_URL + config.PIXID_ENDPOINTS.GET_OCR_STRUCTURED_BY_TICKET;

  const res = await axios.get(URL + ticket, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  const data = await res.data;

  return { data };
};
