import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Spinner from "../../utils/Spinner";
import Navbar from "../Dashboard/Navbar";
import Topbar from "../Dashboard/Topbar";
import PanelMultipleImages from "../../utils/PanelMultipleImages";
import { doCompare, reset } from "./faceSlice";
import LoadImage from "blueimp-load-image";

const Face = ({ loading, user, doCompare, reset, faceLoading, face }) => {
  /**
   * This functions implements the method to draw the detected bouding boxes
   * @function drawFunction
   * @param {Object} data - contains the object to be drawn
   * @param {HTMLCanvasElement} - contains the canvas where the bouding boxes will be drawn
   *  */
  const drawFunction = (data, image1, image2, canvas) => {
    const ctx = canvas.getContext("2d");
    var queryImage = new Image();
    LoadImage(
      image1,
      function (image) {
        image.toBlob(function (e) {
          var blob = window.URL.createObjectURL(e);
          queryImage.src = blob;
        });
      },
      {
        orientation: true,
        canvas: true,
        maxWidth: 800,
        maxHeight: 600,
        noRevoke: true,
      }
    );

    var querySubImage = new Image();

    queryImage.onload = function () {
      canvas.width = queryImage.width;
      canvas.height = queryImage.height;

      ctx.drawImage(queryImage, 0, 0);

      if (data) {
        var top = data.faces.image1.bbox.top;
        var left = data.faces.image1.bbox.left;
        var right = data.faces.image1.bbox.right;
        var bot = data.faces.image1.bbox.bot;

        if (data.match) {
          ctx.fillStyle = "green";
        } else {
          ctx.fillStyle = "red";
        }
        ctx.strokeStyle = ctx.fillStyle;

        ctx.globalAlpha = 1.0;
        ctx.lineWidth = 7;
        ctx.strokeRect(left, top, right - left, bot - top);

        ctx.globalAlpha = 0.2;
        ctx.fillRect(left, top, right - left, bot - top);

        ctx.globalAlpha = 1;
        ctx.strokeRect(left, bot, right - left, 30);
        ctx.fillRect(left, bot, right - left, 30);

        ctx.font = "20px Lato";
        ctx.fillStyle = "white";
        ctx.textBaseline = "bottom";
        if (data.match) {
          ctx.fillText("Confere", left + 5, bot + 25);
        } else {
          ctx.fillText("Não Confere", left + 5, bot + 25);
        }

        ctx.font = "13px Lato";

        LoadImage(
          image2,
          function (image) {
            image.toBlob(function (e) {
              var blob = window.URL.createObjectURL(e);
              querySubImage.src = blob;
            });
          },
          {
            orientation: true,
            canvas: true,
            maxWidth: 800,
            maxHeight: 600,
            noRevoke: true,
          }
        );
      }
    };

    querySubImage.onload = function () {
      ctx.globalAlpha = 1.0;

      ctx.lineWidth = 3;
      ctx.strokeStyle = "white";

      ctx.strokeRect(10, 10, 200, 200);

      ctx.drawImage(
        querySubImage,
        data.faces.image2.bbox.left,
        data.faces.image2.bbox.top,
        data.faces.image2.bbox.right - data.faces.image2.bbox.left,
        data.faces.image2.bbox.bot - data.faces.image2.bbox.top,
        10,
        10,
        200,
        200
      );
    };
  };

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <div className='wrapper'>
          <Navbar activeClass='face' />

          <div id='content'>
            <Topbar username={user} />

            <h4 className='title'>Faces</h4>
            <Link to='/dashboard' className='btn btn-link'>
              <i className='fa fa-arrow-left'></i>&nbsp;&nbsp;Voltar
            </Link>

            <PanelMultipleImages
              label={["Pessoa", "Documento"]}
              message={["Tire a foto do rosto", "Tire a foto do documento"]}
              imageSrc={[
                "https://img.icons8.com/color/480/user.png",
                "https://img.icons8.com/color/480/identification-documents.png",
              ]}
              drawFunction={drawFunction}
              target={doCompare}
              data={face}
              loading={faceLoading}
              resetGlobalState={reset}
              nextStepURL='/documents'
            ></PanelMultipleImages>

            {/* <PanelSingleImage
              message='Tire a foto do rosto'
              imageSrc='https://img.icons8.com/color/480/user.png'
              target={doCount}
              drawFunction={drawFunction}
              resetGlobalState={reset}
              loading={faceLoading}
              data={face}
              nextStepURL='/identification'
            /> */}
          </div>
        </div>
      )}
    </Fragment>
  );
};

Face.propTypes = {
  user: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  faceLoading: PropTypes.bool.isRequired,
  doCompare: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.login.user,
  loading: state.login.loading,
  faceLoading: state.face.loading,
  face: state.face,
});

export default connect(mapStateToProps, { doCompare, reset })(Face);
