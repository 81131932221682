import { createSlice } from "@reduxjs/toolkit";
import { countAxis } from "../API";

export const truckSlice = createSlice({
  name: "axis",
  initialState: {
    loading: false,
  },
  reducers: {
    loading: (state) => {
      state.loading = true;
    },
    error: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    reset: (state) => {
      state.data = null;
      state.cockpit = null;
      state.fuelTanks = null;
      state.axis = null;
      state.error = null;
      state.loading = false;
    },
    count: (state, { payload }) => {
      state.data = payload.data;
      state.cockpit = payload.data.cockpit;
      state.fuelTanks = payload.data.fuelTanks;
      state.axis = payload.data.axis;
      state.loading = false;

      sessionStorage.setItem("axis", JSON.stringify(payload.data));
    },
    check: (state, { payload }) => {
      state.data = payload;
    },
  },
});

export const { loading, error, count, reset, check } = truckSlice.actions;

/**
 * This functions triggers the action that count axis action and dispatch the payload to the interface
 * @function doCount
 * @param {File} image - contains the image containing a truck
 * @param {Object} login - contains the session login information, used to read the auth token
 */
export const doCount = ({ image, login }) => (dispatch) => {
  dispatch(loading());
  countAxis({ image, login })
    .then((data) => dispatch(count(data)))
    .catch((e) => {
      let payload = "Algo deu errado, tente novamente.";
      dispatch(error(payload));
      setTimeout(() => {
        dispatch(reset());
      }, 10000);
    });
};

/**
 * Resets the state to the default
 * @function doReset
 */
export const doReset = () => (dispatch) => {
  dispatch(reset);
};

/**
 * Loads state from session storage
 * @function doCheckAxis
 */
export const doCheckAxis = () => (dispatch) => {
  const payload = JSON.parse(sessionStorage.getItem("axis"));
  dispatch(check(payload));
};

export default truckSlice.reducer;
