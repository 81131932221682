import { createSlice } from "@reduxjs/toolkit";
import { doLogin } from "../API";
import { parseJwt } from "../../../app/utils";

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    isAuthenticated: false,
    token: "",
    user: "",
    error: "",
    loading: true,
  },
  reducers: {
    loading: (state) => {
      state.loading = true;
    },
    error: (state, { payload }) => {
      state.token = "";
      state.user = "";
      state.loading = false;
      state.isAuthenticated = false;
      state.error = payload;
    },
    /**
     * Reducer that sets the state of a logged in user
     * @function reducer/login
     * @param {object} payload - An object with a token atribute, token is a string
     */
    login: (state, { payload }) => {
      sessionStorage.setItem("token", payload.token);

      state.error = "";
      state.loading = false;
      state.isAuthenticated = true;
      state.token = payload.token;
      state.user = parseJwt(payload.token).user_claims["fullname"].split(
        " "
      )[0];
    },
    /**
     * Reducer that clears the state of a logged out user
     * @function reducer/logout
     */
    logout: (state) => {
      sessionStorage.clear();

      state.error = "";
      state.token = "";
      state.user = "";
      state.loading = false;
      state.isAuthenticated = false;
    },
    /**
     * Reducer that checkes if the user is logged in and sets the state
     * @function reducer/logout
     * @param {string} payload - payload is the token loaded from the sessionStorage
     */
    check: (state, { payload }) => {
      state.loading = false;
      state.token = payload;
      state.isAuthenticated = true;
      state.error = "";
      state.user = parseJwt(payload).user_claims["fullname"].split(" ")[0];
    },
  },
});

export const { loading, error, login, logout, check } = loginSlice.actions;

/**
 * Function authenticates the user against the back-end API
 * @function authenticateUser
 * @param {string} username - User email credentials
 * @param {string} password - Password credentials
 */
export const authenticateUser = ({ username, password }) => (dispatch) => {
  dispatch(loading());
  doLogin({ username, password })
    .then((data) => dispatch(login(data)))
    .catch((e) => {
      let payload = "";
      switch (e.response.data.error.name) {
        case "WrongPassword":
          payload = "Usuário e/ou senha incorretos";
          break;
        default:
          payload = "Houve um erro inesperado no servidor";
      }

      dispatch(error(payload));
    });
};

/**
 * Function that logout the user from the system and resets the state
 * @function logoutUser
 */
export const logoutUser = () => (dispatch) => {
  dispatch(logout());
};

/**
 * Function that verifies if the user is logged in
 * @function checkUser
 */
export const checkUser = () => (dispatch) => {
  const token = sessionStorage.getItem("token");
  if (token !== "undefined" && token !== null) {
    dispatch(check(token));
  } else {
    dispatch(logout());
  }
};

export default loginSlice.reducer;
