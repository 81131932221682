import { createSlice } from "@reduxjs/toolkit";
import Quagga from "quagga";

export const barcodeSlice = createSlice({
  name: "nfe",
  initialState: {
    loading: false,
    data: null,
  },
  reducers: {
    loading: (state) => {
      state.loading = true;
    },
    error: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    reset: (state) => {
      state.data = null;
      state.error = null;
      state.loading = false;
    },
    read: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      sessionStorage.setItem("nfe", JSON.stringify(state.data));
    },
    update: (state, { payload }) => {
      console.log(payload);
      state.data = { codeResult: { code: payload } };
      sessionStorage.setItem("nfe", JSON.stringify(state.data));
    },
    check: (state, { payload }) => {
      state.data = payload;
    },
  },
});

export const { loading, error, read, reset, update, check } = barcodeSlice.actions;

export const doBarcodeRead = ({ image }) => async (dispatch) => {
  dispatch(loading());
  var code = null;

  Quagga.decodeSingle(
    {
      src: URL.createObjectURL(image),
      numOfWorkers: 0, // Needs to be 0 when used within node
      inputStream: {
        size: 1280, // restrict input-size to be 800px in width (long-side)
      },
      decoder: {
        readers: ["code_128_reader"], // List of active readers
      },
    },
    (data) => {
      if (data.hasOwnProperty("codeResult")) {
        code = data;
        dispatch(read(data));
      } else {
        if (code === null) {
          dispatch(error("Não foi possível detectar o código de barras."));
        }
      }
    }
  );
};

/**
 * Resets the state to the default
 * @function doReset
 */
export const doReset = () => (dispatch) => {
  dispatch(reset);
};

export const doUpdate = ({ payload }) => (dispatch) => {
  dispatch(update(payload));
};

/**
 * Loads state from session storage
 * @function doCheckBarcode
 */
export const doCheckBarcode = () => (dispatch) => {
  const payload = JSON.parse(sessionStorage.getItem("nfe"));
  dispatch(check(payload));
};

export default barcodeSlice.reducer;
