import React, { useState } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { authenticateUser } from "./loginSlice";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Login.css";
import logo from "./pixforce-logo.svg";
import Spinner from "../../utils/Spinner";
import Message from "../../utils/Message";

const Login = ({ authenticateUser, isAuthenticated, loading, error }) => {
  const [loginForm, setLoginForm] = useState({
    username: "",
    password: "",
  });

  const onChange = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value.trim() });
  };

  const submit = (e) => {
    e.preventDefault();
    authenticateUser(loginForm);
  };

  // Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to='/dashboard'></Redirect>;
  }

  return (
    <div className='container login-container'>
      <div className='row justify-content-center align-self-center'>
        <div className='col justify-content-center align-self-center'>
          <LoginHeader></LoginHeader>

          {error && <Message label={error} type='warning'></Message>}

          {loading ? (
            <Spinner />
          ) : (
            <form
              onSubmit={(e) => {
                submit(e);
              }}
            >
              <div className='form-group'>
                <label htmlFor='login-username'>E-mail do usuário</label>
                <input
                  className='form-control'
                  type='text'
                  name='username'
                  required
                  data-testid='login-username'
                  value={loginForm.username}
                  onChange={(e) => onChange(e)}
                />
              </div>

              <div className='form-group'>
                <label htmlFor='login-password'>Senha</label>
                <input
                  className='form-control'
                  type='password'
                  name='password'
                  required
                  value={loginForm.password}
                  data-testid='login-password'
                  onChange={(e) => onChange(e)}
                />
              </div>

              <button
                className='btn btn-block btn-primary'
                data-testid='login-submit'
              >
                <i className='fa fa-key'></i> Entrar
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  authenticateUser: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.login.isAuthenticated,
  loading: state.login.loading,
  error: state.login.error,
});

export default connect(mapStateToProps, { authenticateUser })(Login);

function LoginHeader() {
  return (
    <header data-testid='login-header'>
      <div className='logo-image'>
        <img src={logo} alt='PixForce Logo' height='50px' />
      </div>
      <h1 className='logo-text'>Pix Gate</h1>
    </header>
  );
}
