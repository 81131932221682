import React from "react";

const Topbar = ({ username }) => {
  return (
    <nav className='navbar navbar-expand-lg navbar-light bg-light'>
      <div className='container-fluid'>
        <button
          type='button'
          id='sidebarCollapse'
          className='btn btn-info'
          onClick={() => {
            if (document.getElementById("sidebar").className) {
              document.getElementById("sidebar").className = "";
            } else {
              document.getElementById("sidebar").className = "active";
            }
          }}
        >
          <i className='fa fa-align-left'> </i>
          <span className='icon-label'>Menu</span>
        </button>

        <div className='left'>
          <i className='fa fa-user'> </i>
          <span className='icon-label'>Olá, {username}!</span>
        </div>
      </div>
    </nav>
  );
};

export default Topbar;
