import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./PanelMultipleImages.css";
import LoadImage from "blueimp-load-image";
import Message from "./Message";
import Spinner from "./Spinner";

function PanelMultipleImages({
  label,
  message,
  imageSrc,
  nextStepURL,
  loading,
  target,
  login,
  resetGlobalState,
  drawFunction,
  data,
}) {
  const [formData, setFormData] = useState({
    imageContent1: null,
    imageContent2: null,
    data: null,
  });

  const [enableForward, setEnableForward] = useState(false);

  /**
   * Update the canvas with the image
   */
  const updateCanvas = (imageContent, id) => {
    if (imageContent && document.getElementById("canvas-panel-" + String(id))) {
      var canvas = document.getElementById("canvas-panel-" + String(id));
      var queryImage = new Image();
      const ctx = canvas.getContext("2d");

      LoadImage(
        URL.createObjectURL(imageContent),
        function (image) {
          image.toBlob(function (e) {
            var blob = window.URL.createObjectURL(e);
            queryImage.src = blob;
          });
        },
        {
          orientation: true,
          canvas: true,
          maxWidth: 800,
          maxHeight: 600,
          noRevoke: true,
        }
      );

      queryImage.onload = function () {
        canvas.width = queryImage.width;
        canvas.height = queryImage.height;
        ctx.drawImage(queryImage, 0, 0);
      };
    }
  };

  const toggleActive = (e) => {
    e.preventDefault();
    const nav = e.target;
    if (nav.name) {
      if (!nav.classList.contains("active")) {
        document.querySelectorAll(".nav-link").forEach((element) => {
          element.classList.remove("active");
        });
        document.querySelectorAll(".tab-pane").forEach((element) => {
          element.classList.remove("active");
        });
      }
      nav.classList.add("active");
      document.querySelector("#" + nav.name + "-tab").classList.add("active");
    }
  };

  const activateTargetTab = (e, tab) => {
    if (e) {
      e.preventDefault();
    }
    document.querySelectorAll(".nav-link").forEach((element) => {
      element.classList.remove("active");
    });
    document.querySelectorAll(".tab-pane").forEach((element) => {
      element.classList.remove("active");
    });
    document.querySelector("#" + tab).classList.add("active");
    document.querySelector("#" + tab + "-tab").classList.add("active");
  };

  const formReset1 = (e) => {
    e.preventDefault();
    setEnableForward(false);
    setFormData({ ...formData, imageContent1: null });
    document.getElementById("form-image-1").value = "";
    const canvas = document.getElementById("canvas-panel-1");
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const formReset2 = (e) => {
    e.preventDefault();
    setEnableForward(false);
    setFormData({ ...formData, imageContent2: null });
    document.getElementById("form-image-2").value = "";
    const canvas = document.getElementById("canvas-panel-2");
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const submit = (e) => {
    e.preventDefault();
    const canvas1 = document.getElementById("canvas-panel-1");
    const canvas2 = document.getElementById("canvas-panel-2");

    canvas1.toBlob((imageData1) => {
      canvas2.toBlob((imageData2) => {
        target({ image1: imageData1, image2: imageData2, login: login });
        // setFormData({ imageContent1: imageData1, imageContent2: imageData2 });
        setEnableForward(true);
      });
    });
  };

  useEffect(() => {
    if (data.data) {
      drawFunction(
        data.data,
        formData.imageContent1,
        formData.imageContent2,
        document.getElementById("canvas-panel-3")
      );
    }
  }, [data, drawFunction, formData.imageContent1, formData.imageContent2]);

  useEffect(() => {
    updateCanvas(formData.imageContent1, "1");
  }, [formData.imageContent1, data]);

  useEffect(() => {
    updateCanvas(formData.imageContent2, "2");
  }, [formData.imageContent2, data]);

  useEffect(() => {
    setEnableForward(formData.imageContent1 !== null && formData.imageContent2 !== null);
  }, [formData.imageContent1, formData.imageContent2, enableForward]);

  useEffect(() => {
    resetGlobalState();
  }, [resetGlobalState]);

  return (
    <form className='center'>
      <br />
      {data.error && <Message label={data.error} type='danger'></Message>}

      {!loading ? (
        <Fragment>
          <ul className='nav nav-pills nav-justified'>
            <li className='nav-item'>
              <a
                name='person'
                id='person'
                className={data.data === null ? "nav-link active" : "nav-link"}
                href='#person-tab'
                onClick={(e) => {
                  toggleActive(e);
                }}
              >
                <i className='fa fa-user'></i> <br /> {label[0]}
              </a>
            </li>
            <li className='nav-item'>
              <a
                name='document'
                id='document'
                className='nav-link'
                href='#document-tab'
                onClick={(e) => {
                  toggleActive(e);
                }}
              >
                <i className='fa fa-id-card'></i> <br /> {label[1]}
              </a>
            </li>

            <li className='nav-item'>
              <a
                name='result'
                id='result'
                className={data.data === null ? "nav-link disabled" : "nav-link active"}
                href='#result-tab'
                onClick={(e) => {
                  toggleActive(e);
                }}
              >
                <i className='fa fa-search'></i> <br /> Resultado
              </a>
            </li>
          </ul>

          <div className='row'>
            <div className='col-12'>
              <div className='tab-content' id='v-pills-tabContent'>
                {/*Aba Pessoa*/}
                <div
                  className={data.data ? "tab-pane fade show" : "tab-pane fade show active"}
                  id='person-tab'
                >
                  <div className='panel-container'>
                    <div className='panel-box' hidden={formData.imageContent1 !== null}>
                      <img
                        className='icon-image'
                        src={imageSrc[0]}
                        height={200}
                        alt='Ícone Upload'
                      />
                      <div className='message-box'>
                        <h5 className='button-header'>{message[0]}</h5>
                        <input
                          accept='image/*'
                          capture='enviroment'
                          type='file'
                          id='form-image-1'
                          className='btn btn-light'
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              imageContent1: e.target.files[0],
                            });
                          }}
                        />
                        <label htmlFor='form-image-1'>
                          <span className='btn btn-outline-info'>Selecionar Imagem</span>
                        </label>
                      </div>
                    </div>

                    <div
                      className='view-container'
                      id='view-container'
                      hidden={formData.imageContent1 === null}
                    >
                      <div className='center'>
                        <canvas id='canvas-panel-1'></canvas>
                      </div>
                    </div>

                    <div className='form-row col-12' hidden={formData.imageContent1 === null}>
                      <div className='form-group col-6'>
                        <button
                          className='btn btn-primary btn-block'
                          onClick={(e) => formReset1(e)}
                        >
                          <i className='fa fa-eraser'></i>&nbsp;&nbsp;Apagar
                        </button>
                      </div>
                      <div className='form-group col-6'>
                        <button
                          className='btn btn-primary btn-block'
                          onClick={(e) => {
                            activateTargetTab(e, "document");
                          }}
                        >
                          <i className='fa fa-arrow-right'></i>&nbsp;&nbsp;Avançar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/*Aba Documento*/}
                <div className='tab-pane fade show' id='document-tab'>
                  <div className='panel-container'>
                    <div className='panel-box' hidden={formData.imageContent2 !== null}>
                      <img
                        className='icon-image'
                        src={imageSrc[1]}
                        height={200}
                        alt='Ícone Upload'
                      />
                      <div className='message-box'>
                        <h5 className='button-header'>{message[1]}</h5>
                        <input
                          accept='image/*'
                          capture='enviroment'
                          type='file'
                          id='form-image-2'
                          className='btn btn-light'
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              imageContent2: e.target.files[0],
                            });
                          }}
                        />
                        <label htmlFor='form-image-2'>
                          <span className='btn btn-outline-info'>Selecionar Imagem</span>
                        </label>
                      </div>
                    </div>

                    <div
                      className='view-container'
                      id='view-container'
                      hidden={formData.imageContent2 === null}
                    >
                      <div className='center'>
                        <canvas id='canvas-panel-2'></canvas>
                      </div>
                    </div>

                    <div className='form-row col-12' hidden={formData.imageContent2 === null}>
                      <div className='form-group col-6'>
                        <button
                          className='btn btn-primary btn-block'
                          onClick={(e) => formReset2(e)}
                        >
                          <i className='fa fa-eraser'></i>&nbsp;&nbsp;Apagar
                        </button>
                      </div>
                      {enableForward && !loading ? (
                        <div className='form-group col-6'>
                          <button className='btn btn-primary btn-block' onClick={(e) => submit(e)}>
                            <i className='fa fa-search'></i>
                            &nbsp;&nbsp;Analisar
                          </button>
                        </div>
                      ) : (
                        <div className='form-group col-6'>
                          <button
                            className='btn btn-primary btn-block'
                            onClick={(e) => activateTargetTab(e, "person")}
                          >
                            <i className='fa fa-arrow-left'></i>&nbsp;&nbsp;Voltar
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/*Aba Resultado*/}
                <div
                  className={data.data ? "tab-pane fade show active" : "tab-pane fade show"}
                  id='result-tab'
                >
                  <div className='panel-container'>
                    <div className='view-container' id='view-container'>
                      <div className='center'>
                        <canvas id='canvas-panel-3'></canvas>
                      </div>
                    </div>

                    <div className='form-row col-12'>
                      <div className='form-group col-6'>
                        <button
                          className='btn btn-primary btn-block'
                          onClick={(e) => {
                            e.preventDefault();
                            resetGlobalState();
                            setFormData({ ...formData, imageContent1: null, imageContent2: null });
                          }}
                        >
                          <i className='fa fa-refresh'></i>&nbsp;&nbsp;Reiniciar
                        </button>
                      </div>
                      <div className='form-group col-6'>
                        <Link className='btn btn-primary btn-block' to={nextStepURL}>
                          <i className='fa fa-arrow-right'></i>&nbsp;&nbsp;Próximo
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <Spinner />
      )}
    </form>
  );
}

PanelMultipleImages.propTypes = {
  message: PropTypes.array.isRequired,
  imageSrc: PropTypes.array.isRequired,
  label: PropTypes.array.isRequired,
  target: PropTypes.func.isRequired,
  drawFunction: PropTypes.func.isRequired,
  resetGlobalState: PropTypes.func.isRequired,
  login: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  login: state.login,
});

export default connect(mapStateToProps, {})(PanelMultipleImages);
