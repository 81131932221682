import { createSlice } from "@reduxjs/toolkit";
import { registerTicket, getTicketStatus, getOCRResults } from "../API";

const parseTicketStatus = (status) => {
  switch (status) {
    case 0:
      return "Registro Inativo";
    case 1:
      return "Registro Ativo";
    case 2:
      return "Aguardando Processamento";
    case 3:
      return "Em Processamento";
    case 4:
      return "Processado";
    case 5:
      return "Cancelado";
    case 6:
      return "Falha no Processamento";
    default:
      return "Status Inesperado - Código " + status;
  }
};

export const documentSlice = createSlice({
  name: "id",
  initialState: {
    loading: false,
    data: null,
  },
  reducers: {
    loading: (state) => {
      state.loading = true;
    },
    error: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    reset: (state) => {
      state.data = null;
      state.error = null;
      state.loading = false;
    },
    register: (state, { payload }) => {
      state.data = {
        statusProcessamento: parseTicketStatus(payload.data.statusProcessamento),
      };
      state.ticket = payload.data.ticket;
      state.loading = true;
    },
    status: (state, { payload }) => {
      state.data = {
        ...state.data,
        statusProcessamento: parseTicketStatus(payload.payload.data.statusProcessamento),
      };
      state.loading = true;
    },
    results: (state, { payload }) => {
      state.data = {
        ...state.data,
        OCR: payload,
      };
      state.loading = false;
    },
  },
});

export const { loading, error, register, reset, status, results } = documentSlice.actions;

export const doDocumentScan = ({ image }) => (dispatch) => {
  dispatch(loading());

  const dataReader = new FileReader();
  dataReader.addEventListener("load", () => {
    // Register the new ticket with the loaded image
    registerTicket({ image: dataReader.result })
      .then((data) => {
        dispatch(loading);
        dispatch(register(data));

        // Checks the status waiting for the job be done
        const fnInterval = setInterval(() => {
          getTicketStatus({ ticket: data.data.ticket }).then((dataStatus) => {
            dispatch(status({ payload: dataStatus }));
            if (dataStatus.data.statusProcessamento > 3) {
              clearInterval(fnInterval);
              // Resolve the OCR values after the document is processed(
              getOCRResults({ ticket: data.data.ticket })
                .then((dataOCR) => {
                  console.log(dataOCR);
                  dispatch(results({ dataOCR }));
                })
                .catch((e) => {
                  dispatch(error(e.toString()));
                  setTimeout(() => {
                    dispatch(reset());
                  }, 10000);
                });
            }
          });
        }, 5000);
      })
      .catch((e) => {
        dispatch(error(e.toString()));
      });
  });

  // dataReader.readAsDataURL(image);
  dispatch(error("O módulo de documentos está desabilitado."));
};

/**
 * Resets the state to the default
 * @function doReset
 */
export const doReset = () => (dispatch) => {
  dispatch(reset);
};

export default documentSlice.reducer;
