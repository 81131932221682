import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import "./Dashboard.css";
import "./Cards.css";
import Navbar from "./Navbar";
import Spinner from "../../utils/Spinner";
import Topbar from "./Topbar";
import { Link } from "react-router-dom";

const Dashboard = ({ user, loading }) => {
  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <div className='wrapper'>
          <Navbar activeClass='dashboard' />

          <div id='content'>
            <Topbar username={user} />

            <div className='container'>
              <h1 className='title'>Dashboard</h1>
              <div className='row'>
                <Card label='Caminhões' color='truck' icon='truck' url='/axis' />
                <Card label='Faces' color='faces' icon='user' url='/faces' />
                <Card label='Documentos' color='cnh' icon='id-card-o' url='/documents' />
                <Card label='Placas' color='ocr' icon='car' url='/plates' />
                <Card label='Nota Fiscal' color='barcode' icon='barcode' url='/barcode' />
                <Card label='Relatório' color='report' icon='file-text-o' url='/report' />
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const Card = ({ url, color, label, icon }) => {
  return (
    <div className='col-sm-12 col-md-6 col-lg-3'>
      <div className='card'>
        <Link to={url} className={`tile-box tile-box-shortcut btn btn-${color}`}>
          <div className='tile-header'>{label}</div>
          <div className='tile-content-wrapper'>
            <i className={`fa fa-${icon}`}></i>
          </div>
        </Link>
      </div>
    </div>
  );
};

Card.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

Dashboard.propTypes = {
  user: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.login.user,
  loading: state.login.loading,
});

export default connect(mapStateToProps, {})(Dashboard);
